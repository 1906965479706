import type { GetStaticProps, NextPage } from "next";

import Layout from "../components/Layout";
import Page from "../components/Page";
import { PageFragment } from "../interfaces";
import getClient from "../lib/getClient";
import getCommonProps, { CommonProps } from "../lib/getCommonProps";

type Props = {
  page: PageFragment;
} & CommonProps;

const Home: NextPage<Props> = ({ page, ...commonProps }) => {
  return (
    <Layout {...commonProps}>
      <Page page={page} />
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const commonData = await getCommonProps(context);
  const sdk = await getClient(context.preview);
  const { pages } = await sdk.PageSlugs({ excludeFromGenerator: true });
  const id = pages.find((page) => page.seo.slug === "home")?.id;

  if (!id) {
    return {
      notFound: true,
    };
  }

  const { page } = await sdk.PageById({ id: id });

  return {
    props: {
      page,
      ...commonData,
    },
  };
};

export default Home;
